import React, { Component } from 'react';
import Select from 'react-select';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './page.css';
import './accounts-groups.css';

import { Button, Table, Form, Modal } from 'react-bootstrap';

import * as appUtils from './utils';

class PageAccountsGroups extends Component {
  constructor(props) {
    super(props);
    this.mounted = true;
    this.state = {
      adata: null,
      aitems: null,
      asort: 0,
      aascending: true,
      afilter: '',

      ashowmodal: false,
      amtitle: '',
      amsavebutton: '',
      amid: 0,
      amuser: '',
      ampassword: '',
      amgroup: 0,
      amemail: '',
      amenabled: true,
      amdevice: '',
      amauto_password: false,
      amsend_email: false,

      gdata: null,
      gitems: null,
      gsort: 0,
      gascending: true,
      gfilter: '',

      gshowmodal: false,
      gmtitle: '',
      gmsavebutton: '',
      gmid: 0,
      gmname: '',
      gmdescription: '',
      gmenabled: true,
    };
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount() {
    this.doFetchData();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
      this.doFetchData();
    }
  }

  render() {
    const groupOptions = this.generateGroupList();
    return (
      <div className="page">
        <h2>Accounts/groups</h2>
        <div className="adminblock">

          <Tabs
            defaultActiveKey="accounts"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="accounts" title="Accounts">
              {/* Accounts tab starts here */}
              <div className="adminheader">
                <h3>{this.props.account} app accounts</h3>
              </div>
              <div className="adminbutton">
                <Button onClick={() => this.clickAccountAdd()}>add account</Button>
                <Form.Control
                  size="sm"
                  type="text"
                  placeholder="search..."
                  value={this.state.afilter}
                  onChange={this.handleAFilterChange.bind(this)}
                />
              </div>
              <div className="admintable">
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>
                        Id
                        <span className="adminheaderbutton">
                          <Button onClick={() => this.sortAClick(0)}>{this.sortAIndicator(0)}</Button>
                        </span>
                      </th>
                      <th>
                        Username
                        <span className="adminheaderbutton">
                          <Button onClick={() => this.sortAClick(1)}>{this.sortAIndicator(1)}</Button>
                        </span>
                      </th>
                      <th>
                        Enabled
                        <span className="adminheaderbutton">
                          <Button onClick={() => this.sortAClick(2)}>{this.sortAIndicator(2)}</Button>
                        </span>
                      </th>
                      <th>
                        Group
                        <span className="adminheaderbutton">
                          <Button onClick={() => this.sortAClick(3)}>{this.sortAIndicator(3)}</Button>
                        </span>
                      </th>
                      <th>
                        Email
                        <span className="adminheaderbutton">
                          <Button onClick={() => this.sortAClick(4)}>{this.sortAIndicator(4)}</Button>
                        </span>
                      </th>
                      <th>
                        Last seen
                        <span className="adminheaderbutton">
                          <Button onClick={() => this.sortAClick(5)}>{this.sortAIndicator(5)}</Button>
                        </span>
                      </th>
                      <th>
                        Device
                        <span className="adminheaderbutton">
                          <Button onClick={() => this.sortAClick(6)}>{this.sortAIndicator(6)}</Button>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{this.state.aitems}</tbody>
                </Table>
              </div>
              {/* Accounts tab ends here */}
            </Tab>
            <Tab eventKey="groups" title="Groups">
              {/* Groups tab starts here */}
              <div className="adminheader">
                <h3>{this.props.account} account groups</h3>
              </div>
              <div className="adminbutton">
                <Button onClick={() => this.clickGroupAdd()}>add group</Button>
                <Form.Control
                  size="sm"
                  type="text"
                  placeholder="search..."
                  value={this.state.gfilter}
                  onChange={this.handleGFilterChange.bind(this)}
                />
              </div>
              <div className="admintable">
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>
                        Id
                        <span className="adminheaderbutton">
                          <Button onClick={() => this.sortGClick(0)}>{this.sortGIndicator(0)}</Button>
                        </span>
                      </th>
                      <th>
                        Group name
                        <span className="adminheaderbutton">
                          <Button onClick={() => this.sortGClick(1)}>{this.sortGIndicator(1)}</Button>
                        </span>
                      </th>
                      <th>
                        Description
                        <span className="adminheaderbutton">
                          <Button onClick={() => this.sortGClick(2)}>{this.sortGIndicator(2)}</Button>
                        </span>
                      </th>
                      <th>
                        Enabled
                        <span className="adminheaderbutton">
                          <Button onClick={() => this.sortGClick(3)}>{this.sortGIndicator(3)}</Button>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{this.state.gitems}</tbody>
                </Table>
              </div>
              {/* Groups tab ends here */}
            </Tab>
          </Tabs>

        </div>

        <Modal show={this.state.gshowmodal} onHide={() => this.modalGClose()} centered>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.gmtitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formBasicName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  value={this.state.gmname}
                  onChange={this.handleGNameChange.bind(this)}
                />
              </Form.Group>
              <Form.Group controlId="formBasicDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Description"
                  value={this.state.gmdescription}
                  onChange={this.handleGDescChange.bind(this)}
                />
              </Form.Group>
              <Form.Group controlId="formBasicChecbox">
                <Form.Check
                  type="checkbox"
                  label="Enable group"
                  checked={this.state.gmenabled}
                  onChange={this.handleGEnableChange.bind(this)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.modalGClose()}>
              Close
            </Button>
            <Button variant="primary" onClick={() => this.modalGSave()}>
              {this.state.gmsavebutton}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.ashowmodal} onHide={() => this.modalAClose()} centered>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.amtitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formBasicName">
                <Form.Control
                  type="text"
                  placeholder="Name"
                  value={this.state.amuser}
                  onChange={this.handleAUserChange.bind(this)}
                />
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={this.state.ampassword}
                  onChange={this.handleAPasswordChange.bind(this)}
                />
              </Form.Group>
              <Form.Group controlId="formBasicChecbox">
                <Form.Check
                  type="checkbox"
                  label="Automatically generate password"
                  checked={this.state.amauto_password}
                  onChange={this.handleAAutoPasswordChange.bind(this)}
                />
              </Form.Group>
              <Form.Group controlId="formBasicName">
                <Form.Control
                  type="text"
                  placeholder="Email address"
                  value={this.state.amemail}
                  onChange={this.handleAEmailChange.bind(this)}
                />
              </Form.Group>
              <Form.Group controlId="formBasicChecbox">
                <Form.Check
                  type="checkbox"
                  label="Send email with login details"
                  checked={this.state.amsend_email}
                  onChange={this.handleASendEmailChange.bind(this)}
                />
              </Form.Group>
              <Form.Group controlId="formBasicChecbox">
                <Form.Check
                  type="checkbox"
                  label="Enable account"
                  checked={this.state.amenabled}
                  onChange={this.handleAEnableChange.bind(this)}
                />
              </Form.Group>
              <Form.Group controlId="formBasicName">
                <Form.Control
                  type="text"
                  placeholder="Device type (optional)"
                  value={this.state.amdevice}
                  onChange={this.handleADeviceChange.bind(this)}
                />
              </Form.Group>
              <Form.Group controlId="formGridState">
                <Form.Label>Member of group</Form.Label>
                <Select
                  options={groupOptions}
                  value={this.state.amgroup}
                  onChange={this.handleAGroupChange.bind(this)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.modalAClose()}>
              Close
            </Button>
            <Button variant="primary" onClick={() => this.modalASave()}>
              {this.state.amsavebutton}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  doFetchData(startDate, endDate) {
    var url = appUtils.apiBaseURL() + 'accounts_groups';
    this.props.functions.spinStart();
    fetch(url, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            //console.log(JSON.stringify(data, null, 4));
            let accounts = data['accounts'];
            for (var i = accounts.length - 1; i >= 0; i--) {
              if (accounts[i].groupname === null) accounts[i].groupname = '';
              if (accounts[i].lastseen === null) accounts[i].lastseen = '';
            }
            this.performAFilter(accounts, this.state.asort, this.state.aascending, this.state.afilter);
            let groups = data['groups'];
            this.performGFilter(groups, this.state.gsort, this.state.gascending, this.state.gfilter);
            if (this.mounted) this.setState({ adata: accounts, gdata: groups });
            this.props.functions.spinStop();
          });
        } else {
          console.log('error 1');
          this.props.functions.spinStop();
        }
      })
      .catch((err) => {
        console.log('error 2');
        this.props.functions.spinStop();
      });
  }

  //Account UI handlers

  generateGroupList(groupid = null) {
    var groupOptions = [{ label: 'none', value: 0 }];
    if (this.state.gdata != null) {
      for (var opt of this.state.gdata) {
        if (opt.name.length > 0) {
          groupOptions.push({ label: opt.name, value: opt.id });
        }
      }
    }
    if (groupid === null) return groupOptions;
    let found = groupOptions.filter((item, index, array) => {
      return item.value === groupid;
    });
    if (found.length !== 1) {
      return groupOptions[0];
    } else {
      return found[0];
    }
  }

  clickAccountAdd() {
    var selGroup = this.generateGroupList(0);
    this.setState({
      amtitle: 'Create new account',
      amsavebutton: 'Create account',
      amid: -1,
      amuser: '',
      ampassword: '',
      amgroup: selGroup,
      amemail: '',
      amenabled: true,
      amdevice: '',
      amauto_password: false,
      amsend_email: true,
      ashowmodal: true,
    });
  }

  clickAccount(id) {
    let found = this.state.adata.filter((item, index, array) => {
      return item.id === id;
    });
    if (found.length !== 1) {
      window.alert('not found');
    } else {
      found = found[0];
      var selGroup = this.generateGroupList(found.groupid);
      this.setState({
        amtitle: 'Update account',
        amsavebutton: 'Update account',
        amid: id,
        amuser: found.name,
        ampassword: '',
        amgroup: selGroup,
        amemail: found.email,
        amenabled: found.enabled,
        amdevice: found.device,
        amauto_password: false,
        amsend_email: true,
        ashowmodal: true,
      });
    }
  }

  sortAIndicator(n) {
    if (this.state.asort !== n) {
      return '-';
    } else {
      if (this.state.aascending) {
        return '▼';
      } else {
        return '▲';
      }
    }
  }

  sortAClick(n) {
    let newSort = n;
    let newAsc = true;
    if (this.state.asort === newSort) {
      newAsc = !this.state.aascending;
    } else {
      newAsc = true;
    }
    this.performAFilter(this.state.adata, newSort, newAsc, this.state.afilter);
    this.setState({
      asort: newSort,
      aascending: newAsc,
    });
  }

  handleAFilterChange(e) {
    let filter = e.target.value;
    this.performAFilter(this.state.adata, this.state.asort, this.state.aascending, filter);
    this.setState({ afilter: filter });
  }

  performAFilter(data, sort, asc, filter) {
    //Filter
    let filterfinal = filter.toLowerCase();
    let filtered = data.filter((item, index, array) => {
      let itemstring = (item.id + item.name + item.groupname + item.email + item.device).toLowerCase();
      return itemstring.indexOf(filterfinal) >= 0;
    });
    //Sort
    let fn = (a, b) => a - b;
    switch (sort + (asc === true ? 0 : 100)) {
      case 0:
        fn = (a, b) => a.id - b.id;
        break;
      case 100:
        fn = (a, b) => b.id - a.id;
        break;
      case 1:
        fn = (a, b) => a.name < b.name;
        break;
      case 101:
        fn = (a, b) => b.name < a.name;
        break;
      case 2:
        fn = (a, b) => b.enabled - a.enabled;
        break;
      case 102:
        fn = (a, b) => a.enabled - b.enabled;
        break;
      case 3:
        fn = (a, b) => a.groupname < b.groupname;
        break;
      case 103:
        fn = (a, b) => b.groupname < a.groupname;
        break;
      case 4:
        fn = (a, b) => a.email < b.email;
        break;
      case 104:
        fn = (a, b) => b.email < a.email;
        break;
      case 5:
        fn = (a, b) => a.lastseen < b.lastseen;
        break;
      case 105:
        fn = (a, b) => b.lastseen < a.lastseen;
        break;
      case 6:
        fn = (a, b) => a.device < b.device;
        break;
      case 106:
        fn = (a, b) => b.device < a.device;
        break;
      default:
    }
    filtered.sort(fn);
    //Final result to JSX
    let aitems = filtered.map((item, index, array) => {
      return (
        <tr key={'aitems' + index} onClick={() => this.clickAccount(item.id)}>
          <td>{item.id}</td>
          <td>{item.name}</td>
          <td>{item.enabled === 1 ? '✓' : '✗'}</td>
          <td>{item.groupname}</td>
          <td>{item.email}</td>
          <td>{item.lastseen}</td>
          <td>{item.device}</td>
        </tr>
      );
    });
    this.setState({ aitems: aitems });
  }

  handleAUserChange(e) {
    this.setState({ amuser: e.target.value });
  }

  handleAPasswordChange(e) {
    this.setState({ ampassword: e.target.value });
  }

  handleAAutoPasswordChange(e) {
    this.setState({ amauto_password: e.target.checked });
  }

  handleAEmailChange(e) {
    this.setState({ amemail: e.target.value });
  }

  handleASendEmailChange(e) {
    this.setState({ amsend_email: e.target.checked });
  }

  handleAEnableChange(e) {
    this.setState({ amenabled: e.target.checked });
  }

  handleADeviceChange(e) {
    this.setState({ amdevice: e.target.value });
  }

  handleAGroupChange(e) {
    this.setState({ amgroup: e });
  }

  modalAClose() {
    this.setState({ ashowmodal: false });
  }

  modalASave() {
    this.setState({ ashowmodal: false });
    let messageFinal = JSON.stringify({
      id: this.state.amid,
      user: this.state.amuser,
      password: this.state.ampassword,
      group: this.state.amgroup.value,
      email: this.state.amemail,
      enabled: this.state.amenabled,
      device: this.state.amdevice,
      auto_password: this.state.amauto_password,
      send_email: this.state.amsend_email,
    });
    var url = appUtils.apiBaseURL() + 'post_account';
    fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'include', // include, same-origin, *omit
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // no-referrer, *client
      body: messageFinal, // body data type must match "Content-Type" header
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            //console.log(JSON.stringify(data, null, 4));
            this.doFetchData();
          });
        } else {
          console.log('error 1');
        }
      })
      .catch((err) => {
        console.log('error 2');
      });
  }

  //Group UI handlers

  clickGroupAdd() {
    this.setState({
      gmtitle: 'Add new group',
      gmsavebutton: 'Add group',
      gmid: -1,
      gmname: '',
      gmdescription: '',
      gmenabled: true,
      gshowmodal: true,
    });
  }

  clickGroup(id) {
    let found = this.state.gdata.filter((item, index, array) => {
      return item.id === id;
    });
    if (found.length !== 1) {
      window.alert('not found');
    } else {
      found = found[0];
      this.setState({
        gmtitle: 'Update group',
        gmsavebutton: 'Update group',
        gmid: id,
        gmname: found.name,
        gmdescription: found.description,
        gmenabled: found.enabled,
        gshowmodal: true,
      });
    }
  }

  sortGIndicator(n) {
    if (this.state.gsort !== n) {
      return '-';
    } else {
      if (this.state.gascending) {
        return '▼';
      } else {
        return '▲';
      }
    }
  }

  sortGClick(n) {
    let newSort = n;
    let newAsc = true;
    if (this.state.gsort === newSort) {
      newAsc = !this.state.gascending;
    } else {
      newAsc = true;
    }
    this.performGFilter(this.state.gdata, newSort, newAsc, this.state.gfilter);
    this.setState({
      gsort: newSort,
      gascending: newAsc,
    });
  }

  handleGFilterChange(e) {
    let filter = e.target.value;
    this.performGFilter(this.state.gdata, this.state.gsort, this.state.gascending, filter);
    this.setState({ gfilter: filter });
  }

  performGFilter(data, sort, asc, filter) {
    //Filter
    let filterfinal = filter.toLowerCase();
    let filtered = data.filter((item, index, array) => {
      let itemstring = (item.id + item.name + item.description + item.enabled).toLowerCase();
      return itemstring.indexOf(filterfinal) >= 0;
    });
    //Sort
    let fn = (a, b) => a - b;
    switch (sort + (asc === true ? 0 : 100)) {
      case 0:
        fn = (a, b) => a.id - b.id;
        break;
      case 100:
        fn = (a, b) => b.id - a.id;
        break;
      case 1:
        fn = (a, b) => a.name < b.name;
        break;
      case 101:
        fn = (a, b) => b.name < a.name;
        break;
      case 2:
        fn = (a, b) => a.description < b.description;
        break;
      case 102:
        fn = (a, b) => b.description < a.description;
        break;
      case 3:
        fn = (a, b) => a.enabled < b.enabled;
        break;
      case 103:
        fn = (a, b) => b.enabled < a.enabled;
        break;
      default:
    }
    filtered.sort(fn);
    //Final result to JSX
    let gitems = filtered.map((item, index, array) => {
      return (
        <tr key={'gitems' + index} onClick={() => this.clickGroup(item.id)}>
          <td>{item.id}</td>
          <td>{item.name}</td>
          <td>{item.description}</td>
          <td>{item.enabled === 1 ? '✓' : '✗'}</td>
        </tr>
      );
    });
    this.setState({ gitems: gitems });
  }

  modalGClose() {
    this.setState({ gshowmodal: false });
  }

  handleGNameChange(e) {
    this.setState({ gmname: e.target.value });
  }

  handleGDescChange(e) {
    this.setState({ gmdescription: e.target.value });
  }

  handleGEnableChange(e) {
    this.setState({ gmenabled: e.target.checked });
  }

  modalGSave() {
    this.setState({ gshowmodal: false });
    let messageFinal = JSON.stringify({
      id: this.state.gmid,
      group: this.state.gmname,
      description: this.state.gmdescription,
      enabled: this.state.gmenabled,
    });
    var url = appUtils.apiBaseURL() + 'post_group';
    fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'include', // include, same-origin, *omit
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // no-referrer, *client
      body: messageFinal, // body data type must match "Content-Type" header
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            //console.log(JSON.stringify(data, null, 4));
            this.doFetchData();
          });
        } else {
          console.log('error 1');
        }
      })
      .catch((err) => {
        console.log('error 2');
      });
  }
}

export default PageAccountsGroups;
